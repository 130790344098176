/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: deea_jo (https://sketchfab.com/deeajo)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/old-desk-7b690bb22d874444af290267438fb9f8
title: old desk
*/

import React, { Suspense, useRef } from 'react'
import { useGLTF, OrbitControls, OrthographicCamera, PerspectiveCamera } from '@react-three/drei'
import { Canvas, useFrame } from "react-three-fiber"
import styled from "styled-components"

const GridContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

function OldDeskModel({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('glb/oldDesk.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, -75.06, 424]} rotation={[Math.PI / 2, 0, 0]} scale={0.03}>
        <group rotation={[-Math.PI, 0, 0]}>
          <group position={[0, 212, 65]}>
            <mesh geometry={nodes.BIROU_LP_Birou_0.geometry} material={materials.Birou} />
          </group>
          <group position={[0, 173.42, 0]}>
            <mesh geometry={nodes.SCAUN_LP_chair_0.geometry} material={materials.chair} />
          </group>
          <group position={[14.71, 206.38, 77.31]} rotation={[0, 0, -Math.PI / 2]} scale={0.58}>
            <mesh geometry={nodes.sigilii_COIN_0.geometry} material={materials.COIN} />
          </group>
          <group position={[22.82, 195.49, 78.62]} rotation={[-0.07, -0.06, -0.53]}>
            <mesh geometry={nodes.foaie2_Paper1_0.geometry} material={materials.Paper1} />
          </group>
          <group position={[14.98, 205.68, 78.21]} rotation={[0, 0, -1.71]}>
            <mesh geometry={nodes.foaie1_Paper2_0.geometry} material={materials.Paper2} />
          </group>
          <group position={[-24.24, 228.45, 76.88]} rotation={[Math.PI / 2, 0, 0]} scale={0.5}>
            <group position={[0, 24.21, 0.25]}>
              <mesh geometry={nodes.polySurface1_Lamp_0.geometry} material={materials.Lamp} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/gltf/oldDesk.gltf')


export default function OldDesk() {
  return (
    <GridContainer>
      <Canvas 
        style={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgb(255,255,255, 0.1)",
          borderRadius: "10px",
          margin:"10px",
          height: "68vh",
          width: "60vh",
          zIndex: "1"
        }}
        camera={{
          position: [0,0,0],
          near: 3,
          fov: 20
        }}
      >


        <ambientLight intensity={1} />
        <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
          <Suspense fallback={null}>
            <OldDeskModel />
          </Suspense>
          <OrbitControls />
      </Canvas>
    </GridContainer>
  )
}



