import { motion } from 'framer-motion'
import { GridItemStyle } from '../grid-item'
import "../../Styles/App.css"

const variants = {
    hidden: { opacity: 0, x: 0, y: 20 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: 20 }
}


const Layout = ({ children, title }) => (
  <motion.article
  initial="hidden"
  animate="enter"
  exit="exit"
  variants={variants}
  transition={{duration: 0.4, type: 'easInOut'}}
  className="Layout"
  >
      <>
        {title && (
          <div>
            <title>{title} - Darkskittlz </title>
          </div>
        )}
        {children}
        <GridItemStyle />
      </>
    </motion.article>
)

export default Layout